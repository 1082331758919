
import GluttonRecorder from './glutton-record';
import { ModelWidgetInterface, WidgetHelped, AjaxResponse } from "../../types";
import { Envs, Ajax, Apis, Utils, OssKit, Consts } from '../../common';
import { DateFNS } from '../../common/3rd';


// const GLUTTON_SERVER_HOST = 'glutton-demo.ebaocloud.com.cn';
// const GLUTTON_MAX_SLEEP = 1000;//最大等待秒数

type GluttonTokenType = {
	firstToken?: string;
	serverHost?: string;
}

function captureException(a,b){
	//将日志上传到oss
	try{
		const newBlob = new Blob([JSON.stringify({
			a,
			b
		})], {
			type: 'application/json'
		});
		let traceNo = Utils.fromQueryString().gluttonTraceNo;
		if(Utils.isBlank(traceNo)){
			//@ts-ignore
			traceNo = window.GluttonContext['traceNo'];
		}
		if(Utils.isBlank(traceNo)){
			traceNo = Utils.generateUUID();	
		}
		const ossKey = `intergation/${Consts.STS_FOLDER}/${DateFNS.format(new Date(),'YYYYMMDD')}/${DateFNS.format(new Date(),'YYYYMMDDHHmmss')}-${traceNo}.json`;
		OssKit.getInstance().uploadFile(newBlob, ossKey);
	}catch{
		console.log(a);
		console.log(b);
	}
}

const initGluttonRecorder = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}) => {
	// issue21839，现在桌面端也需要录制可回溯
	//桌面端放开
	// if (Envs.isDesk()) {
	// 	// 桌面不需要
	// 	return Promise.resolve();
	// }
	//开关没开不启用
	if (!Utils.isTrue(Envs.getEnvValue('GLUTTON_ALLOWED'))) {
		return Promise.resolve();
	}
	const queryString = Utils.fromQueryString();
	const { delegated = '' } = queryString;
	//判断租户,缓存中可能还没有，从账号上截取租户code
	const { tenantCode = delegated?.substr(0, delegated?.indexOf('.')) } = Envs.findAccount();
	return new Promise<void>((resolve, reject) => {
		Ajax.post(
			Apis.GLUTTON_ASK_TOKEN, { ...options, tenantCode }
		).then((data: AjaxResponse) => {
			const { firstToken, serverHost } = (data.body || {}).body || {} as GluttonTokenType;
			if (!Utils.isBlank(firstToken) && !Utils.isBlank(serverHost)) {
				//@ts-ignore
				resolve(new GluttonRecorder({
					firstToken: firstToken,
					serverHost: serverHost,
					captureSdkException:(a,b)=>{ captureException(a,b) },
					disableLogs: false,
					dataAutoPlay: false,
					recordOptions: {
						iconFonts: ["Font-Awesome5.14"]
					}
				}));
			} else {
				resolve();
			}
		}).catch(error => {
			resolve();
		});
	})
}


let started = false;



const startRecord = async (delay?: number): Promise<void> => {
	if (started) {
		console.log("GluttonRecorder Started Already!");
	}
	started = true
	console.log("GluttonRecorder Start Recording!");
	let start = new Date().getTime();
	try {
		// @ts-ignore
		if (window.gluttonRecorder) {
			if (delay && delay > 0) {
				// @ts-ignore
				setTimeout(() => window.gluttonRecorder.startRecord(), delay);
			} else {
				// @ts-ignore
				window.gluttonRecorder.startRecord();
			}
		}
	} catch (e) {
		console.error(e);
	} finally {
		let end = new Date().getTime();
		console.log('start const time', end, start, end - start);
	}
	return Promise.resolve();
}

const stopRecord = async (): Promise<void> => {
	if (!started) {
		console.log("GluttonRecorder No Started!");
	}
	console.log("GluttonRecorder End Recording!");
	let start = new Date().getTime();
	try {
		// @ts-ignore
		if (window.gluttonRecorder) {
			// @ts-ignore
			await window.gluttonRecorder.stopRecord();
		}
	} catch (e) {
		console.error(e);
	} finally {
		let end = new Date().getTime();
		console.log('stop cost time', end, start, end - start);
	}
	return Promise.resolve();
}

const forceSubmitRecord = async (): Promise<void> => {
    if (!started) {
        console.log("GluttonRecorder No Started!");
    }
    console.log("GluttonRecorder force submit!");
    let start = new Date().getTime();
    try {
		//@ts-ignore
        if (window.gluttonRecorder) {
			//@ts-ignore
            await window.gluttonRecorder.forceSubmit();
        }
    } catch (e) {
        console.error(e);
    } finally {
        let end = new Date().getTime();
        console.log('force submit time', end, start, end - start);
    }
    return Promise.resolve();
}

// const sleep = (time: number): Promise<void> => {
// 	return new Promise((resolve) => setTimeout(resolve, time));
// }

const putGluttonContext = (key: string, value: any) => {
	//@ts-ignore
	let gluttonContext = window.GluttonContext || {};
	gluttonContext[key] = value;
	//@ts-ignore
	window.GluttonContext = gluttonContext;
}

const updateOrderNoAndPolicyNo = (invoker: WidgetHelped & ModelWidgetInterface,) => {
	//投保过程中，orderNo和policyNo是在中间步骤生产，需要在生成后塞进GluttonContext
	const policy = invoker.getRootModel();
	//@ts-ignore
	let gluttonContext = window.GluttonContext || {};
	gluttonContext.orderNo = policy.quotationNo;
	gluttonContext.policyNo = policy.policyNo;
	//@ts-ignore
	window.GluttonContext = gluttonContext;
}

enum GluttonConstans {
	PRODUCT_NAME = 'productName',
	TRACE_NO = 'traceNo',
	POLICY_NO = 'policyNo',
	ORDER_NO = 'orderNo',
	QUOTE_NO = 'quoteNo',
	PAY_NO = 'payNo',
	PAY_ORDER_NO = 'payOrderNo',
	POLICY_HOLDER_NAME = 'policyHolderName',
	POLICY_HOLDER_ID_NO = 'policyHolderIdNo',
	POLICY_HOLDER_MOBILE = 'policyHolderMobile',
	ALL_STEPS = 'allSteps',
	CURRENT_STEP = 'currentStep',
	SOURCE = 'source',
	VERDOR_NAME = 'uniqueNo1',
	SOURCE_FACTOR = 'uniqueNo3',
	VERSION = 'version',
	TYPE = 'uniqueNo5'
}

export { startRecord, stopRecord, forceSubmitRecord, putGluttonContext, GluttonConstans, updateOrderNoAndPolicyNo, initGluttonRecorder }

